<template>
	<div>
		<device-type-icon v-for="(d,i) of deviceCounts" :key="i" :type="d.type_code" :text="d.radio + d.nonradio" class="mr-2" />
		<v-chip v-if="totalDevicesRadio > 0" label dark title="Радио" small class="mr-2">
			<v-icon small left color="green">mdi-wifi</v-icon>
			{{ totalDevicesRadio }}
		</v-chip>
		<v-chip v-if="totalDevicesNonRadio > 0" label dark title="Не-радио" small class="mr-2">
			<v-icon small left>mdi-wifi-off</v-icon>
			{{ totalDevicesNonRadio }}
		</v-chip>
		<v-chip v-if="totalDevicesExpired > 0" label dark title="Изтекли" small class="mr-2">
			<v-icon small left color="warning">mdi-alert</v-icon>
			{{ totalDevicesExpired }}
		</v-chip>
	</div>
</template>

<script>
import DeviceTypeIcon from "@/ittijs/components/DeviceDataList/DeviceTypeIcon.vue";

export default {
	components: {
		DeviceTypeIcon
	},

	props: {
		deviceCounts: {},
	},

	data() {
		return {
		}
	},

	computed: {
		totalDevicesRadio() {
			return (this.deviceCounts || []).reduce((cnt, elem) => cnt + elem.radio, 0);
		},
		totalDevicesNonRadio() {
			return (this.deviceCounts || []).reduce((cnt, elem) => cnt + elem.nonradio, 0);
		},
		totalDevicesExpired() {
			return (this.deviceCounts || []).reduce((cnt, elem) => cnt + elem.expired, 0);
		},
	},

}
</script>